<template>
	<div class="pui-form">
		<v-form @submit.prevent class="mb-4 pb-4" ref="form" v-model="valid" lazy-validation v-if="modelLoaded">
			<div row wrap class="pui-form-layout">
				<pui-field-set :title="$t('pmdatasourcetype.title')">
					<v-layout wrap>
						<v-flex xs12 md6>
							<pui-text-field
								:label="$t('pmdatasourcetype.name')"
								v-model="model.name"
								required
								toplabel
								maxlength="150"
							></pui-text-field>
						</v-flex>
						<v-flex xs12 md6>
							<div class="label_container">
								<label class="fixed_label">
									{{ this.$t('pmdatasourcetype.icon') }}
									<span style="color: var(--R-200)">*</span>
								</label>
							</div>
							<v-select
								v-model="iconSelected"
								:items="items"
								:placeholder="this.$t('pmdatasourcetype.phicon')"
								clearable
								:disabled="formDisabled"
								rounded
								:rules="[rules.required]"
								persistent-placeholder
								class="pt-5 px-1 mt-auto"
								variant="outlined"
							>
								<template v-slot:selection="{ item }">
									<div :style="itemStyle(item)"></div>
									{{ item.name }}
								</template>
								<template v-slot:item="{ item }">
									<div :style="itemStyle(item)"></div>
									{{ item.name }}
								</template>
							</v-select>
						</v-flex>
					</v-layout>
					<v-layout wrap>
						<v-flex xs12 md4>
							<pui-select
								v-if="isSuperAdmin"
								attach="pmorganizationid"
								:label="this.$t('pmdatasourcetype.organizationname')"
								:placeholder="this.$t('pmdatasourcetype.phorganization')"
								toplabel
								clearable
								:disabled="formDisabled"
								required
								v-model="model"
								modelName="vluppmorganization"
								:modelFormMapping="{ pmorganizationid: 'pmorganizationid' }"
								:itemsToSelect="organizationItemsToSelect"
								itemValue="pmorganizationid"
								itemText="name"
							></pui-select>
							<pui-text-field
								v-else
								:label="$t('pmdatasourcetype.organizationname')"
								toplabel
								disabled
								required
								v-model="organizationName"
							></pui-text-field>
						</v-flex>
						<v-flex xs12 md4>
							<pui-select
								attach="pmdatamodelid"
								:label="this.$t('pmdatasourcetype.pmdatamodel')"
								:placeholder="this.$t('pmdatasourcetype.phdatamodel')"
								toplabel
								clearable
								required
								reactive
								:disabled="formDisabled || this.model.pmorganizationid == null"
								v-model="model"
								modelName="vluppmdatamodel"
								:modelFormMapping="{ pmdatamodelid: 'pmdatamodelid' }"
								:itemsToSelect="datamodelItemsToSelect"
								itemValue="pmdatamodelid"
								itemText="name"
								:fixedFilter="pmdatamodelFixedFilter"
								filterId="pmorganizationid"
								:filterMap="{ pmorganizationid: model.pmorganizationid }"
								:filterParentMap="{ pmorganizationid: 'id' }"
							></pui-select>
						</v-flex>
						<v-flex xs12 md4>
							<pui-text-field
								v-model="model.elasticacronym"
								:label="$t('pmdatasourcetype.elasticacronym')"
								maxlength="30"
								toplabel
								required
								:tooltipDescription="!isUpdateEntry ? infoElasticAcronym : null"
								:disabled="!isSuperAdmin && isUpdateEntry"
							></pui-text-field>
						</v-flex>
					</v-layout>
					<v-layout wrap>
						<v-flex xs12>
							<pui-text-area
								v-model="model.description"
								:label="$t('pmdatasourcetype.description')"
								maxlength="250"
								toplabel
							></pui-text-area>
						</v-flex>
					</v-layout>
					<v-layout wrap>
						<v-flex xs12 md4>
							<pui-checkbox
								:label="$t('pmdatasourcetype.disabled')"
								v-model="model.disabled"
								true-value="1"
								false-value="0"
								style="margin-top: -1em !important"
							></pui-checkbox>
						</v-flex>
					</v-layout>
					<pui-field-set :title="$t('pmdatasourcetype.parameters')">
						<v-layout wrap>
							<pui-multi-select
								id="multiSelectParameters"
								itemValue="pmparameterid"
								itemText="name"
								v-model="model.parameters"
								:items="isSuperAdmin ? filteredParameters : allPmParameters"
								:itemsToSelect="model.parameters"
								:disabled="formDisabled"
							></pui-multi-select>
						</v-layout>
					</pui-field-set>
					<pui-field-set :title="$t('pmdatasourcetype.staticattributes')">
						<v-layout wrap>
							<pui-multi-select
								id="multiSelectStaticAttrs"
								itemValue="pmstaticattributesid"
								itemText="name"
								v-model="model.staticattributes"
								:items="isSuperAdmin ? filteredStaticAttributes : allPmStaticattributes"
								:itemsToSelect="model.staticattributes"
								:disabled="formDisabled"
							></pui-multi-select>
						</v-layout>
					</pui-field-set>
				</pui-field-set>
			</div>
			<!-- footer -->
			<pui-form-footer v-if="!isModalDialog">
				<pui-form-footer-btns
					:formDisabled="formDisabled"
					:saveDisabled="saving"
					:saveAndNew="saveAndNew"
					:saveAndUpdate="saveAndUpdate"
					:save="insertLayerAndSave"
					:back="back"
				></pui-form-footer-btns>
			</pui-form-footer>
		</v-form>
		<pui-form-loading v-else></pui-form-loading>
	</div>
</template>

<script>
import PuiFormMethodsMixin from 'pui9-mixins/PuiFormMethodsMixin';

import { search, filterDependOnRole, isSuperAdmin, getOrganizationId, getIconsType } from '@/api/common';
import constants from '@/utils/constants';

export default {
	name: 'pmdatasourcetypeform',
	mixins: [PuiFormMethodsMixin],

	data() {
		return {
			modelName: 'pmdatasourcetype',
			model: 'pmdatasourcetype',
			allPmParameters: [],
			allPmStaticattributes: [],
			isSuperAdmin: false,
			items: [],
			iconSelected: null,
			pmdatamodelFixedFilter: {},
			rules: {
				select: [(v) => !!v || this.$t('common.required')]
			},
			infoElasticAcronym: 'sp-organization_acronym-elastic_acronym'
		};
	},
	watch: {
		iconSelected: {
			handler() {
				console.log("iconid is: ",this.model.pmiconid)
				this.model.pmiconid = this.iconSelected ? this.iconSelected.pmiconsid : null;
			},

			deep: true
		},
		'model.pmorganizationid': function (oldValue, newValue) {
			if(this.model.pmorganizationid == null || (oldValue != newValue && newValue != null)){
				this.model.pmdatamodelid = null;
			}
		}
	},
	computed: {
		datamodelItemsToSelect() {
			return [{ pmdatamodelid: this.model.pmdatamodelid }];
		},
		organizationItemsToSelect() {
			return [{ pmorganizationid: this.model.pmorganizationid }];
		},
		organizationName() {
			return this.userProperties['organizationname'];
		},
		organizationId() {
			return getOrganizationId(this.userProperties);
		},
		isUpdateEntry() {
			return typeof this.model == 'object' && !!this.model.pmdatasourcetypeid;
		},
		filteredStaticAttributes() {
			if (this.model && this.model.pmorganizationid) {
				return this.allPmStaticattributes.filter((staticattribute) => staticattribute.pmorganizationid == this.model.pmorganizationid);
			}
			return [];
		},
		filteredParameters() {
			if (this.model && this.model.pmorganizationid) {
				return this.allPmParameters.filter((parameter) => parameter.pmorganizationid == this.model.pmorganizationid);
			}
			return [];
		}
	},
	beforeCreate() {
		if (this.model) {
			this.modelLoaded = true;
			if (!isSuperAdmin(this.session.profiles[0])) {
				this.model.pmorganizationid = this.userProperties['organizationid'];
			}
		}
	},
	created() {},
	mounted() {
		this.items = getIconsType(constants.DTS_ICONS_TYPE_ACRONYM, this.$store.getters.getIcons).concat(
			getIconsType(constants.DEFAULT_DTS_ICONS_TYPE, this.$store.getters.getIcons)
		);
		this.items = this.items.sort((a, b) => {
			if (a.acronym < b.acronym) return -1;
			if (a.acronym > b.acronym) return 1;
			return 0;
		});
		console.log('This items', this.items);

		this.isSuperAdmin = isSuperAdmin(this.session.profiles[0]);
		if (!this.isSuperAdmin) {
			this.pmdatamodelFixedFilter = {
				groups: [],
				groupOp: 'and',
				rules: [
					{
						field: 'pmorganizationid',
						op: 'eq',
						data: this.userProperties.organizationid
					}
				]
			};
		}
	},
	beforeUpdate() {
		this.iconSelected = this.items.find((element) => element.pmiconsid == this.model.pmiconid);
	},
	updated() {
		if (this.model) {
			if (!this.isSuperAdmin && !this.model.pmorganizationid) {
				this.model.pmorganizationid = this.userProperties['organizationid'];
			}
		}
	},
	methods: {
		async afterGetData() {
			//MultiSelect: Parameter
			let filterUser = filterDependOnRole(this.userProperties);
			let body = {
				model: 'pmparameter',
				rows: 999,
				filter: filterUser
			};

			let { data } = await search(body);
			console.log('After get data - Parameters');
			console.log(data);
			this.allPmParameters = data.data;
			//MultiSelect: Static Attributes
			body = {
				model: 'pmstaticattributes',
				rows: 999,
				filter: filterUser
			};

			data = (await search(body)).data;
			console.log('After get data - Static Attributes');
			console.log(data);
			this.allPmStaticattributes = data.data;
		},

		itemStyle(item) {
			var appProperties = this.$store.getters.getApplication;
			return 'content: url(' + appProperties.urlbase + item.route + '); margin-right: 1rem; transform: scale(0.7); width: 38px';
		},
		async insertLayer(/* shouldStayInPage = true */) {
			let hasNotificationLyer = Object.values(this.$store.getters.layers).find(
				(layer) => layer.pmdatasourcetypeid == this.model.pmdatasourcetypeid && layer.model == constants.NOTIFICATIONS_MODEL
			);
			console.debug('has notif layer', hasNotificationLyer);
			/* let url =  this.$store.getters.getModelByName('pmspatialtables').url.insert;
			this.$puiRequests.postRequest(
				url,
				this.model,
				(response) => {
					//this.saving = false;
					//this.$store.$puiEvents.$emit(`onPui-insertRow-dataTable-${this.modelDependency.name}`, response.data);
					//this.$store.$puiNotify.success(this.$t('pui9.save.success'));
					//this.afterSave && this.afterSave(response.data);
					console.debug('filter response', response.data);
					if (response.data.pmfilterconditionid) {
						this.model.pmfilterconditionid = response.data.pmfilterconditionid;
					}
					if (!(shouldStayInPage === true)) {
						if (this.internal.afterInsertGoToUpdate) {
							this.goToUpdate(response.data);
						} else if (this.internal.afterInsertGoToCreate) {
							// Router not allow to go same router with same parameters
							this.refreshRouter();
						} else {
							this.back();
						}
						this.resetAfterSaveActions();
					}
				},
				(error) => {
					this.resetAfterSaveActions();
					this.saving = false;
					this.onSaveError && this.onSaveError();
					this.onError(error);
				}
			); */
		},
		hasActiveDevices(id) {
			let deviceType = this.$store.getters.getDatasourcetype.find((datasourcetype) => datasourcetype.pmdatasourcetypeid == id);
			console.log('devicetype debug', this.$store.getters.getDatasourcetype, deviceType);
			if (deviceType && deviceType.activestations > 0) {
				return true;
			}
			return false;
		},
		insertLayerAndSave() {
			//this.insertLayer(); DEPRECATED
			console.log('devicetype debug', this.model.disabled, this.model.pmdatasourcetypeid);

			if (this.model.disabled == 1 && this.model.pmdatasourcetypeid) {
				if (this.hasActiveDevices(this.model.pmdatasourcetypeid)) {
					this.$puiNotify.error(this.$t('pmdatasourcetype.actions.disableErrorForm'), this.$t('pmdatasourcetype.actions.cannotDisable'));
					this.saving = false;
					return;
				}
				this.save();
			}
			this.save();
		}
	}
};
</script>
<style scoped>
.label_container {
	position: relative;
}

.fixed_label {
	position: absolute !important;
	top: auto !important;
	left: 5px !important;
	color: rgba(0, 0, 0, 0.87);
}
</style>
